.hs-button {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="14" viewBox="0 0 24 14" fill="none"><path d="M15.6697 0.634277L22.634 7.00009M22.634 7.00009L15.6697 13.3659M22.634 7.00009H0" stroke="black"/></svg>'),
    linear-gradient(90deg, #ebe212 -39.12%, #09c0e9 100%);
  background-repeat: no-repeat, no-repeat;
  background-position:
    right 10px center,
    0 0;
  color: black;
  padding: 8px 16px;
  border-radius: 5px;
  margin-top: 24px;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  padding-right: 50px;
}

.hs-button:hover {
  box-shadow: 0 0 15px #ebe412be;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="14" viewBox="0 0 24 14" fill="none"><path d="M15.6697 0.634277L22.634 7.00009M22.634 7.00009L15.6697 13.3659M22.634 7.00009H0" stroke="black"/></svg>'),
    linear-gradient(90deg, #ebe212 -39.12%, #68ce8e 100%);
}

